<template>
  <div class="error-page">
    <div class="msg-box">
      <h1 class="animate__animated animate__fadeInDown">
        {{ $t("common.errors.error") }}
      </h1>
      <h2 class="animate__animated animate__fadeInUp">
        {{ $t("common.messages.failedToLoadThePage") }}
      </h2>
    </div>
  </div>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped>
@import "../assets/scss/_variables";
@import "../assets/scss/_mixins";
.error-page {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 100px;
}

.msg-box {
  flex-direction: column;

  h1 {
    @include text-bordered-shadow();
    color: $text-color-accent;
    font-weight: 900;
  }
}
</style>
