import axios from "axios";
import { format, isValid, parseISO } from "date-fns";
import Loading from "ui/src/Loading.vue";
import Error from "ui/src/Error.vue";
import { SuccessFilled } from "@element-plus/icons-vue";
import { ElNotification } from "element-plus";
import { jwtDecode } from "jwt-decode";
import isEmpty from "lodash/isEmpty";
import has from "lodash/has";
import merge from "lodash/merge";
import {
    computed,
    defineAsyncComponent,
    defineComponent,
    h,
    type AsyncComponentLoader,
} from "vue";
import endpoints from "../apis/endpoints";
import {
    BooleanValue,
    type TransferMail,
    type FromInbox,
    languageTypeCode,
    COMPANY_TYPE_CODE,
} from "../types";
import { clearAllStorageData, getCookie, setCookie } from "./cache";
import {
    CACHE,
    KAINOS_RESPONSE_STATUS_TYPE,
    MAIL_TYPE,
    EMAIL_TYPE_CODES,
    HOSUrl,
} from "./constants";
import dayjs from "dayjs";

let isRefreshingToken = false;
let refreshPromise: Promise<string> | null = null;

const externalConfig =
    typeof __EXTERNAL_CONFIGS__ !== "undefined" ? __EXTERNAL_CONFIGS__ : {};
const metaEnv: ImportMeta["env"] = merge({}, import.meta.env, externalConfig);

export const defineAsyncComponentWithOptions = (
    loader: AsyncComponentLoader,
    isHideLoading?: boolean,
) => {
    return defineAsyncComponent({
        loader: loader,
        ...(!isHideLoading && {
            loadingComponent: Loading,
            errorComponent: Error,
        }),
    });
};

export const getEnv = ({
    name,
    defaultValue,
}: {
    name: string;
    defaultValue?: string;
}): string => {
    const value = has(metaEnv, name) ? metaEnv[name] : defaultValue;
    return value || "";
};

export const baseURL = getEnv({
    name: "VITE_BASE_URL",
    defaultValue: "https://api-dev.hnv.vvnst.com/api/",
});

const getExpireTimeBefore5Minutes = (exp: number) => {
    return Math.round((exp - Date.now() / 1000 - 300) / 60);
};

const getExpireToken = (token: string) => {
    if (!token) {
        return null;
    }
    const decodedToken: { exp: number } = jwtDecode(token);
    const expireTime = getExpireTimeBefore5Minutes(decodedToken.exp);
    if (expireTime < 0) {
        return null;
    }
    return token;
};

export const setToken = (token: string) => {
    const decodedToken: { Details: any; exp: number } = jwtDecode(token);
    const decodedRefreshToken: { exp: number } = jwtDecode(
        decodedToken.Details.refreshToken,
    );
    const expireTime = getExpireTimeBefore5Minutes(decodedToken.exp);
    const expireRefreshTime = getExpireTimeBefore5Minutes(
        decodedRefreshToken.exp,
    );
    setCookie(CACHE.ACCESS_TOKEN, token, expireTime, true);
    setCookie(
        CACHE.REFRESH_TOKEN,
        decodedToken.Details.refreshToken,
        expireRefreshTime,
        true,
    );
};

const handleSessionExpired = () => {
    ElNotification({
        title: "Error",
        message: "Session expired",
        type: "error",
        position: "top-left",
    });
    const userInfo = getCookie(CACHE.USER_INFO);
    const url =
        userInfo?.vmsCompanyCode == COMPANY_TYPE_CODE.HOS ? HOSUrl : "/login";
    clearAllStorageData();
    window.location.href = url;
    isRefreshingToken = false;
    refreshPromise = null;
};

const refreshTokenRequest = () => {
    if (isRefreshingToken) return refreshPromise;
    isRefreshingToken = true;
    const refreshToken = getExpireToken(getCookie(CACHE.REFRESH_TOKEN, true));
    if (!refreshToken) {
        handleSessionExpired();
        return Promise.reject(new Error("Session expired"));
    }
    refreshPromise = axios
        .post(baseURL + endpoints.common.refreshToken, {
            refreshToken,
        })
        .then((res) => {
            if (
                res.status === 200 &&
                res.data.common.status === KAINOS_RESPONSE_STATUS_TYPE.SUCCESS
            ) {
                const newToken = res.data.data.token;
                setToken(newToken);
                isRefreshingToken = false;
                refreshPromise = null;
                return newToken;
            }
        })
        .catch((err) => {
            handleSessionExpired();
            throw err;
        });
    return refreshPromise;
};

export const jwt = () => {
    const token = getExpireToken(getCookie(CACHE.ACCESS_TOKEN, true));
    return token || refreshTokenRequest();
};

export const formatDateFromTimestamp = (timestamp: number): string => {
    if (typeof timestamp !== "number" || isNaN(timestamp)) {
        return "invalid date format";
    }
    const date = new Date(timestamp * 1000);
    const formattedDate = format(date, "yyyy-MM-dd HH:mm");
    return formattedDate;
};

/**
 * Highlights the keyword in the given text.
 * @param body The text to highlight.
 * @param keyword The keyword to highlight.
 * @param isEnabled Whether to enable the highlighting.
 * @returns The highlighted text.
 */
export const highlightKeyword = (
    body: string,
    keyword?: string,
    isEnabled: boolean = false,
) => {
    if (keyword && isEnabled) {
        const regex = new RegExp(`(${keyword})(?!([^<]+)?>)`, "gi");
        return `${body.replace(regex, '<span style="background-color: yellow;">$1</span>')}`;
    } else {
        return body;
    }
};

export const handleIconsDoc = (row: {
    isAttachment: BooleanValue;
    isUrgent: BooleanValue;
    isConfidential: BooleanValue;
    isReplyRequested: BooleanValue;
}) => {
    const icons = [];
    if (row.isAttachment === BooleanValue.Yes) {
        icons.push({
            class: "mdi mdi-file-document-outline",
            tooltip: "common.labels.attachments",
        });
    }
    if (row.isUrgent === BooleanValue.Yes) {
        icons.push({
            class: "mdi mdi-alert-decagram-outline",
            tooltip: "common.labels.urgent",
        });
    }
    if (row.isConfidential === BooleanValue.Yes) {
        icons.push({
            class: "mdi mdi-lock",
            tooltip: "common.labels.confidential",
        });
    }
    if (row.isReplyRequested === BooleanValue.Yes) {
        icons.push({
            class: "mdi mdi-reply-all",
            tooltip: "common.labels.replyRequested",
        });
    }
    return icons;
};

/**
 * Formats a given date string to the specified format.
 * @param dateRaw The date string to format.
 * @param formatStr The format string to use. Default is "yyyy-MM-dd HH:mm".
 * @returns The formatted date string or "Invalid date format" if the input date is invalid.
 */
export const formatDate = (
    dateRaw: string,
    formatStr: string = "yyyy-MM-dd HH:mm",
): string => {
    try {
        if (dateRaw) {
            const date = parseISO(dateRaw);
            if (date && !isValid(date)) {
                return "Invalid date format";
            } else {
                return format(date, formatStr);
            }
        } else {
            return dateRaw;
        }
    } catch (error) {
        console.error("Error formatting date:", error);
        return dateRaw;
    }
};

export const HtmlContent = defineComponent({
    props: {
        text: {
            type: String,
            required: true,
        },
        keyword: {
            type: String,
            default: "",
        },
        isEnabled: {
            type: Boolean,
            default: false,
        },
        maxLength: {
            type: Number,
            required: false,
        },
    },
    setup(props) {
        const isTruncated = computed(
            () => props.maxLength && props.text.length > props.maxLength,
        );
        const newText = computed(() =>
            isTruncated.value
                ? `${props.text.slice(0, props.maxLength)}...`
                : props.text,
        );
        const body = computed(() =>
            highlightKeyword(newText.value, props.keyword, props.isEnabled),
        );
        return () =>
            h("div", {
                class: "preview-body",
                innerHTML: body.value,
            });
    },
});

export const onCheckPositiveInteger = (
    value: string | number,
    isEqualZero: boolean,
): number => {
    if (value !== "") {
        const numberValue = Number(value);
        if (
            !isNaN(numberValue) &&
            Number.isInteger(numberValue) &&
            (isEqualZero ? numberValue >= 0 : numberValue > 0)
        ) {
            return numberValue;
        }
    }
    return isEqualZero ? 0 : 1;
};

export const parseToDisplay = (p: Array<{ email: string; name: string }>) => {
    return p.map((item) => `${item.name}(${item.email})`).join(", ");
};

export const parseTransferMail = (to: TransferMail[]) => {
    return to
        ?.map((item: TransferMail) => {
            if (item.name !== null || item.email !== null)
                return item.name || item.email;
        })
        .join(", ");
};

export const parseDisplayFrom = (from: FromInbox, locale: string) => {
    if (!isEmpty(from.pic)) {
        return from.pic;
    }
    if (locale === languageTypeCode.KR && !isEmpty(from.koreanName)) {
        return from.koreanName;
    }
    if (!isEmpty(from.englishName)) {
        return from.englishName;
    }
    return from.emailAddress;
};

export const parseDateOnDatePickerToDateStr = (date: any): string => {
    if (!date) {
        return "";
    }
    const dateDayJS = dayjs(date);
    return `${dateDayJS.year()}${dateDayJS.format("MM")}${dateDayJS.format("DD")}`;
};

export const parseDateStrBeToDateOnDatePicker = (dateStr: string): string => {
    if (!dateStr) {
        return "";
    }
    const date = dayjs(dateStr, "YYYYMMDD", true);
    if (!date.isValid()) {
        return "Invalid date";
    }
    return date.format("YYYY-MM-DD");
};

export const isMailAddressFormat = (mail: string) => {
    if (mail) {
        return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/g.test(mail);
    }
};
export const isPasswordValidate = (password: string) => {
    if (password) {
        return /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]+$/g.test(
            password,
        );
    }
};
export const getToday = () => {
    return dayjs().format("YYYY-MM-DD");
};

export const parseDisplayMailType = (payload: any) => {
    const mailTypes: string[] = [];
    if (payload?.isConfidential === BooleanValue.Yes) {
        mailTypes.push(MAIL_TYPE.CONFIDENTIAL);
    }
    if (payload?.isUrgent === BooleanValue.Yes) {
        mailTypes.push(MAIL_TYPE.URGENT);
    }
    if (payload?.isReplyRequested === BooleanValue.Yes) {
        mailTypes.push(MAIL_TYPE.REPLY_REQUESTED);
    }
    return mailTypes.join(", ");
};

export const getCombinedUserNames = (
    korName: string,
    enName: string,
    separator: string = " / ",
) => {
    const names = [];
    if (korName && !isEmpty(korName)) {
        names.push(korName);
    }
    if (enName && !isEmpty(enName)) {
        names.push(enName);
    }
    return names.join(separator);
};

export const calculateDateRange = (
    isStartDate: boolean,
    month: number,
    date?: string,
) => {
    const referenceDate = date ? dayjs(date) : dayjs();
    if (date) {
        return isStartDate
            ? referenceDate.add(month, "month").format("YYYYMMDD")
            : referenceDate.subtract(month, "month").format("YYYYMMDD");
    } else {
        return isStartDate
            ? referenceDate.subtract(month, "month").format("YYYYMMDD")
            : referenceDate.format("YYYYMMDD");
    }
};

export const getDeviceTimezoneOffset = (): string => {
    const now = new Date();
    const timezoneOffsetMinutes = now.getTimezoneOffset();
    const hoursOffset = Math.abs(timezoneOffsetMinutes) / 60;
    const minutesOffset = Math.abs(timezoneOffsetMinutes) % 60;
    const sign = timezoneOffsetMinutes <= 0 ? "+" : "-";
    const formattedTimezoneOffset = `GMT${sign}${String(Math.floor(hoursOffset)).padStart(2, "0")}:${String(minutesOffset).padStart(2, "0")}`;
    return formattedTimezoneOffset;
};

export const parseStartsWithAddress = (email: string = ""): string => {
    const prefix = `${EMAIL_TYPE_CODES.ADDRESS_BOOK}-`;
    return `${email.startsWith(prefix)}${email}`;
};

export const validateID = (id: string) => {
    if (id) {
        return /^[A-Za-z0-9]+$/g.test(id);
    }
};

export const validatePassword = (password: string) => {
    const regex =
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;

    if (password) {
        return regex.test(password);
    }
};

export const checkNewPasswordSameAsId = (id: string, password: string) =>
    !(id && password === id);

export const checkNewPasswordSameAsOld = (
    newPassword: string,
    currentPassword: string,
) => newPassword !== currentPassword;

export const checkNewPasswordSameAsConfirm = (
    newPassword: string,
    confirmPassword: string,
) => newPassword === confirmPassword;

export const notifyComplete = (msg: string) => {
    ElNotification({
        icon: SuccessFilled,
        message: msg,
        customClass: "complete-message",
        type: "success",
        showClose: false,
    });
};
