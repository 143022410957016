export const HOSUrl = "http://hiworks.hos21.com/";

export enum ActionKey {
    Receipt = "receipt",
    EmailDetail = "emailDetail",
    FollowUp = "followUp",
    Convert = "convert",
    Forward = "forward",
    Move = "move",
    Delete = "delete",
    New = "new",
    Resend = "resend",
    ApprovalStatus = "approvalStatus",
    RecordType = "recordType",
    AddAddress = "addAddress",
    Redraft = "redraft",
    Edit = "edit",
    Reply = "reply",
    ReplyAll = "replyAll",
    AddressDetail = "addressDetail",
    PortList = "portList",
    SupplierList = "supplierList",
    StoreRequisition = "storeRequisition",
    VesselCode = "vesselCode",
    StoreHistoryDetail = "storeHistoryDetail",
    BudgetDetail = "budgetDetail",
    DeleteItem = "deleteItem",
    Save = "save",
    Submit = "submit",
    ActionFailed = "actionFailed",
    DocumentNumber = "documentNumber",
    TotalNc = "totalNC",
    SentFlag = "sentFlag",
    Position = "position",
    Name = "name",
    Total = "total",
    Today = "today",
    Nc = "nc",
    I = "I",
    L = "L",
    K = "K",
    Weekly = "Weekly",
    Received = "received",
    View = "view",
}

export enum REDRAFT_FROM_KEY {
    DRAFT = "draft",
    SENT = "sent",
    INBOX = "inbox",
}

export enum CACHE {
    //common
    LOCALE = "LOCALE",
    USER_INFO = "USER_INFO",
    ACCESS_TOKEN = "ACCESS_TOKEN",
    REFRESH_TOKEN = "REFRESH_TOKEN",
    USER_SESSION = "USER_SESSION",
    PORT_CODES = "PORT_CODES",
    RECORD_TYPES = "RECORD_TYPES",
    COMMON_CODES = "COMMON_CODES",
    LANGUAGE = "LANGUAGE",
    KAINOS_MESSAGES = "KAINOS_MESSAGES",
    USR_PRF = "USR_PRF",
    PIC_USER = "PIC_USER",
    NATION_CODES = "NATION_CODES",
    VSL_CD = "VSL_CD",
    SUPPLIER = "SUPPLIER",

    SMPL_USR_LST = "SMPL_USR_LST",

    /* Procurement */
    DOC_NO = "DOC_NO",

    // Spare Part
    SPARE_PART_DETAIL = "SPARE_PART_DETAIL",
    SPARE_PART = "SPARE_PART",
    GROUP_BUDGET = "GROUP_BUDGET",
    MACHINERY = "MACHINERY",
    EQUIPMENT = "EQUIPMENT",
    DRAWING_NO_LIST = "DRAWING_NO_LIST",
    TREEVIEWDATA = "TREEVIEWDATA",
    PROC_STATUS_QUOTATION = "PROC_STATUS_QUOTATION",
    PROC_STATUS_QUOTATION_ITEM_LIST = "PROC_STATUS_QUOTATION_ITEM_LIST",

    // Quotation
    QUOTATION_LST = "QUOTATION_LST",

    // Order
    ORDER_LIST = "ORDER_LIST",

    //receipt
    RECEIPT_LIST = "RECEIPT_LIST",

    //crew-work
    WORK_PATTERN = "WORK_PATTERN",
    WORKING_SCHEDULE = "WORKING_SCHEDULE",
    CREW_MAPPING = "CREW_MAPPING",
    WORK_TIME_RECORD_LIST_RULE = "WORK_TIME_RECORD_LIST_RULE",
    WORK_TIME_RECORD = "WORK_TIME_RECORD",

    // Process Monitor
    OWNER_COMPANY = "OWNER_COMPANY",
    MANAGEMENT_COMPANY = "MANAGEMENT_COMPANY",
    FLEET = "FLEET",

    // PaymentStatus
    PAYMENT_STATUS_LST = "PAYMENT_STATUS_LST",

    // Invoice
    INVOICE_LST = "INVOICE_LST",
}

export enum KAINOS_RESPONSE_STATUS_TYPE {
    SUCCESS = "S",
}

export const FLAG_YES = "Yes";
export const FLAG_NO = "No";

export enum GROUP_CODE {
    APMNT_KND_CD = "APMNT_KND_CD",
    BANK_KND_CD = "BANK_KND_CD",
    BDY_PART_CD = "BDY_PART_CD",
    BLOO_DTL_KND_CD = "BLOO_DTL_KND_CD",
    BLOO_KND_CD = "BLOO_KND_CD",
    CALD_KND_CD = "CALD_KND_CD",
    CMPNY_AND_PSN_KND_CD = "CMPNY_AND_PSN_KND_CD",
    CNTRY_CD = "CNTRY_CD",
    COLB_TP_CD = "COLB_TP_CD",
    CONG_AND_CONDL_CD = "CONG_AND_CONDL_CD",
    CONG_AND_CONDL_RGN_CD = "CONG_AND_CONDL_RGN_CD",
    CRW_KND_CD = "CRW_KND_CD",
    DDCT_KND_CD = "DDCT_KND_CD",
    DIBRK_LOC_CD = "DIBRK_LOC_CD",
    DISA_CAGY_CD = "DISA_CAGY_CD",
    DISA_TP_CD = "DISA_TP_CD",
    DISE_CLSS_CD = "DISE_CLSS_CD",
    EDCN_FOM_CD = "EDCN_FOM_CD",
    EDCN_KND_CD = "EDCN_KND_CD",
    EDCN_LOC_CD = "EDCN_LOC_CD",
    EDCN_LVL_KND_CD = "EDCN_LVL_KND_CD",
    EVAL_CMPNY_KND_CD = "EVAL_CMPNY_KND_CD",
    EVA_AGE_KND_CD = "EVA_AGE_KND_CD",
    EVA_TP_CD = "EVA_TP_CD",
    FLG = "FLG",
    FMLY_RLT_CD = "FMLY_RLT_CD",
    FNL_EDCN_LVL_SCH_CD = "FNL_EDCN_LVL_SCH_CD",
    GND_CD = "GND_CD",
    GNTE_KND_CD = "GNTE_KND_CD",
    SARY_STEP_CD = "SARY_STEP_CD",
    GRDT_KND_CD = "GRDT_KND_CD",
    HEAR_ABIY_CD = "HEAR_ABIY_CD",
    HELT_INSUR_DDCT_KND_CD = "HELT_INSUR_DDCT_KND_CD",
    HQ_CD = "HQ_CD",
    INCNT_KND_CD = "INCNT_KND_CD",
    INDST_ACDNT_CUZ_CD = "INDST_ACDNT_CUZ_CD",
    ISS_RGN_CD = "ISS_RGN_CD",
    ITVW_KND_CD = "ITVW_KND_CD",
    JOB_INJ_INTR_EXT_KND_CD = "JOB_INJ_INTR_EXT_KND_CD",
    LANG_TP_CD = "LANG_TP_CD",
    MILI_BRNC_CD = "MILI_BRNC_CD",
    MILI_DCHG_RSN_CD = "MILI_DCHG_RSN_CD",
    MILI_KND_CD = "MILI_KND_CD",
    MILI_MAN_PWR_ADM_CD = "MILI_MAN_PWR_ADM_CD",
    MILI_RNK_CD = "MILI_RNK_CD",
    MILI_SVC_KND_CD = "MILI_SVC_KND_CD",
    MILI_SVC_TP_CD = "MILI_SVC_TP_CD",
    MJR_CD = "MJR_CD",
    NTN_PENS_DDCT_KND_CD = "NTN_PENS_DDCT_KND_CD",
    OCCU_PLC_CD = "OCCU_PLC_CD",
    PAY_BNS_KND_CD = "PAY_BNS_KND_CD",
    PAY_CD = "PAY_CD",
    PAY_FLET_CD = "PAY_FLET_CD",
    PAY_ITEM_CD = "PAY_ITEM_CD",
    PAY_ITEM_DTL_CD = "PAY_ITEM_DTL_CD",
    POSTN_CD = "POSTN_CD",
    PRMTN_AVG_SCRE_CD = "PRMTN_AVG_SCRE_CD",
    PRMTN_EXCLSN_CD = "PRMTN_EXCLSN_CD",
    PRMTN_KND_CD = "PRMTN_KND_CD",
    PSN_PENS_CTRT_TP_CD = "PSN_PENS_CTRT_TP_CD",
    PSPRT_TP_CD = "PSPRT_TP_CD",
    PSTV_NGTV_CD = "PSTV_NGTV_CD",
    QUAN_CD = "QUAN_CD",
    RAT_CD = "RAT_CD",
    RCRUIT_KND_CD = "RCRUIT_KND_CD",
    RELI_CD = "RELI_CD",
    RESI_FOM_CD = "RESI_FOM_CD",
    REWA_PUNI_KND_CD = "REWA_PUNI_KND_CD",
    REWA_PUNI_TP_CD = "REWA_PUNI_TP_CD",
    RGN_CD = "RGN_CD",
    RNK_CD = "RNK_CD",
    RTMNT_RSN_CD = "RTMNT_RSN_CD",
    SCTR_CD = "SCTR_CD",
    SGL_LIST_CD = "SGL_LIST_CD",
    SHP_TP_KND_CD = "SHP_TP_KND_CD",
    SPCTY_ABIY_CD = "SPCTY_ABIY_CD",
    STCW_EDCN_KND_CD = "STCW_EDCN_KND_CD",
    SUP_ITEM_CD = "SUP_ITEM_CD",
    SUP_ITEM_SZ_CD = "SUP_ITEM_SZ_CD",
    TENU_CD = "TENU_CD",
    TRMNT_PLC_CNTRY_CD = "TRMNT_PLC_CNTRY_CD",
    TRMNT_PLC_KND_CD = "TRMNT_PLC_KND_CD",
    UNLD_ROTN_PLC_CD = "UNLD_ROTN_PLC_CD",
    USR_ACT_CD = "USR_ACT_CD",
    VACC_CD = "VACC_CD",
    VISA_TP_CD = "VISA_TP_CD",
    VSL_LINE_TP_CDWAGE_WRK_KND_CD = "VSL_LINE_TP_CDWAGE_WRK_KND_CD",
    WORK_PIC_CD = "WORK_PIC_CD",
    EML_SNT_STUS_CD = "EML_SNT_STUS_CD",
    FLEX_KND_CD = "FLEX_KND_CD",
    EML_APRV_STP = "EML_APRV_STP",
    EML_DEPT_TP_CD = "EML_DEPT_TP_CD",
    EML_RCVE_STUS_CD = "EML_RCVE_STUS_CD",
    WRK_REC_PATT_CD = "WRK_REC_PATT_CD",
    WRK_REC_SKD_CD = "WRK_REC_SKD_CD",
    WRK_REC_NONC_RULE_CD = "WRK_REC_NONC_RULE_CD",
    WRK_REC_RPT_TP_CD = "WRK_REC_RPT_TP_CD",
    MNU_TGT_CD = "MNU_TGT_CD",
    FLW_UP_STUS_CD = "FLW_UP_STUS_CD",
    IMO_VSL_TP_CD = "IMO_VSL_TP_CD",
    DTRB_CTRL_SYS_MTHD_CD = "DTRB_CTRL_SYS_MTHD_CD",
    EU_MVR_VSL_TP_CD = "EU_MVR_VSL_TP_CD",
    MVR_MTHD_CD = "MVR_MTHD_CD",
    ICE_CLSS_CD = "ICE_CLSS_CD",
    STTE_VENDR_CD = "STTE_VENDR_CD",
    STTE_SVC_CD = "STTE_SVC_CD",
    STTE_TP_CD = "STTE_TP_CD",
    WRK_RSN_CD = "WRK_RSN_CD",
    VMS_APVLST_CD = "VMS_APVLST_CD",
    CLM_TP_CD = "CLM_TP_CD",
    PST_KND_CD = "PST_KND_CD",
    VMS_VSL_TP_CD = "VMS_VSL_TP_CD",
    WRK_DY_TP_CD = "WRK_DY_TP_CD",
    DEFE_ITEM_STUS_CD = "DEFE_ITEM_STUS_CD",
}

export const COMMON_PAGINATION_DF = {
    pageNumber: 0,
    pageSize: 20,
    totalPages: 0,
    totalCount: 0,
};

export enum DEFAULT_STATUS {
    SUBMITTED = "Submitted",
    WAITING = "Waiting",
}

export enum MAIL_TYPE {
    URGENT = "Urgent",
    CONFIDENTIAL = "Confidential",
    REPLY_REQUESTED = "Reply Requests",
}

export enum SORT_DIRECTIONS {
    ASCENDING = "ascending",
    DESCENDING = "descending",
    ASC_KEY = "asc",
    DESC_KEY = "desc",
}

export enum FORMAT_DATE_TIME {
    NUMERIC = "YYYYMMDD",
    YEARMONTH = "YYYYMM",
    ISO_8601 = "YYYY-MM-DD",
    TIME_24 = "HH:mm:ss",
    TIME_NOT_SECOND_24 = "HH:mm",
    TIME_12 = "hh:mm:ss A",
    TIME_NOT_SECOND_12 = "hh:mm A",
    yyyy_MM = "yyyy-MM",
    YYYY_MM = "YYYY-MM",
    DATE = "yyyy-MM-dd",
}

export const SIZE_UNITS = {
    KB_TO_BYTE: 1024,
    MB_TO_BYTE: 1048576,
    GB_TO_BYTE: 1073741824,
};

export const FILE_SIZES = {
    MB_10: 10485760,
    GB_2: 2147483648,
};

export const DEFAULT_UPLOAD_FILES_LIMIT = 10;

export const APPROVAL_STATUS = {
    SBMT: "Submitted",
    WT: "Waiting",
    APRV: "Approved",
    RJCT: "Rejected",
};

export const APPROVAL_STATUS_CODE = {
    SUBMITTED: "SBMT",
    WAITING: "WT",
    APPROVED: "APRV",
    REJECTED: "RJCT",
    IN_PROGRESS: "PROS",
    DRAFT: "DRFT",
};

export const APPROVAL_STATUS_TYPE = {
    APPROVAL: "Approval",
    DRAFTER: "Drafter",
    FINAL: "Final",
};

export enum MAIL_STATUS {
    PROC = "PROC",
    APRV = "APRV",
    RJCT = "RJCT",
    PROS = "PROS",
}

export const END_CONTENT_DRAFT_APPROVAL = "<!--END_CONTENT_DRAFT_APPROVAL-->";
export enum LANG_TP_CD {
    EN = "01",
    KR = "02",
}

export enum KainosMessageByModule {
    COMMON = "common",
    COMMON_CODE = "commonCode",
    MAIL = "mail",
    APPROVAL = "approval",
    USER_INFORMATION = "userInformation",
    MAINTENANCE = "maintenance",
    CREW = "crew",
    PROCUREMENT = "procurement",
    REQUISITION = "requisition",
    PROCESS = "process",
    MASTER = "master",
    EPURCHASE = "epurchase",
    RECEIPT = "receipt",
    BUDGET = "budget",
    WAGES = "wages",
    EWMS = "ewms",
    OPERATION = "operation",
    EPRO = "epro",
    DOCUMENT = "document",
    ADMINISTRATION = "administration",
    SAFETY = "safety",
}

export const EMAIL_TYPE_CODES = {
    ADDRESS_BOOK: "A",
    GROUP: "G",
    EXTERNAL: "E",
};

export enum TYPE_KIND {
    STORE = "STORE",
    OIL = "OIL",
    SPARE_PART = "SPARE_PART",
    SPARE_PART_VALUE = "SPARE PART",
    BUNKER = "BUNKER",
    LUB_OIL = "LUB_OIL",
    LUB_OIL_VALUE = "LUB OIL",
    SPARE_PART_CAMEL = "Spare Part",
    STORE_CAMEL = "Store",
    LUB_OIL_CAMEL = "Oil",
    BUNKER_CAMEL = "Bunker",
}

export const MAIL_OPTIONS_SEARCH = [
    {
        value: "query",
        label: "common.labels.all",
    },
    {
        value: "title",
        label: "common.labels.title",
    },
    {
        value: "content",
        label: "common.labels.content",
    },
];

export const APPROVAL_OPTIONS_SEARCH = [
    {
        value: "query",
        label: "common.labels.all",
    },
    {
        value: "title",
        label: "common.labels.title",
    },
    {
        value: "content",
        label: "common.labels.content",
    },
    {
        value: "drafter",
        label: "approval.labels.drafter",
    },
    {
        value: "docNo",
        label: "common.labels.docNo",
    },
];

export const unregisteredMailColumn = [
    {
        key: "title",
        label: "common.labels.title",
        align: "left",
        minWidth: 150,
    },
    {
        key: "from",
        label: "common.labels.from",
        align: "left",
        width: 300,
    },
    {
        key: "transmitted",
        label: "common.labels.transmitted",
        sortable: "custom",
        align: "center",
        minWidth: 120,
        width: 200,
    },
];

export enum SYSTEM_INFO_MODULE_CODE {
    SHIP_MAIL = "SHIPMAIL",
    PERSONAL_MAIL = "PERSONAL_EMAIL",
    TEAM_MAIL = "TEAMMAIL",
    CREW = "CREW",
}

export enum APPROVAL_TYPE {
    APPROVAL = "Approval",
    REJECT = "Reject",
}

export const COMPLETE_MESSAGES = {
    SUBMIT: "common.messages.submitComplete",
    SAVE: "common.messages.saveCompleteMessage",
    REJECT: "common.messages.rejectSuccess",
    APPROVAL: "common.messages.approveComplete",
    SEND: "common.messages.sendComplete",
};
