<template>
  <div class="loading-view">
    <img src="../assets/images/hmm_progressBar5.gif" alt="loading" />
  </div>
</template>

<style lang="scss" scoped>
.loading-view {
  background: #a6b0bc;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 80px;
  }
}
</style>
