import Cookies from "js-cookie";
import AES from "crypto-js/aes";
import encUtf8 from "crypto-js/enc-utf8";
import type { CACHE } from "./constants";
import { getEnv } from "..";

export const setCache = (key: CACHE, value: any, encrypted?: boolean) => {
    const expire = new Date().getTime() + 1000 * 60 * 60 * 6;
    const valueToStore = encrypted
        ? AES.encrypt(
              JSON.stringify({ value, expire }),
              getEnv({ name: "VITE_LOCAL_STORAGE_KEY" }),
          ).toString()
        : JSON.stringify({ value, expire });
    localStorage.setItem(key, valueToStore);
};

export const getCache = (key: CACHE, encrypted?: boolean) => {
    const expire = new Date().getTime();
    const cache = localStorage.getItem(key);
    if (cache) {
        const { value, expire: cacheExpire } = encrypted
            ? JSON.parse(
                  AES.decrypt(
                      cache,
                      getEnv({ name: "VITE_LOCAL_STORAGE_KEY" }),
                  ).toString(encUtf8),
              )
            : JSON.parse(cache);
        if (expire < cacheExpire) {
            return value;
        }
    }
    return null;
};

export const removeCache = (key: CACHE) => {
    localStorage.removeItem(key);
};

export const setCookie = (
    key: CACHE,
    value: any,
    expire?: number,
    encrypted?: boolean,
) => {
    const expireOptions = expire
        ? { expires: new Date(Date.now() + expire * 60 * 1000) }
        : {};
    const valueToStore = encrypted
        ? AES.encrypt(
              JSON.stringify({ value }),
              getEnv({ name: "VITE_LOCAL_STORAGE_KEY" }),
          ).toString()
        : JSON.stringify({ value });
    Cookies.set(key, JSON.stringify(valueToStore), expireOptions);
};

export const getCookie = (key: CACHE, encrypted?: boolean) => {
    const cookieValue = Cookies.get(key);
    if (cookieValue) {
        try {
            const parsedValue = JSON.parse(cookieValue);
            const decryptedValue = encrypted
                ? AES.decrypt(
                      parsedValue,
                      getEnv({ name: "VITE_LOCAL_STORAGE_KEY" }),
                  ).toString(encUtf8)
                : parsedValue;
            return JSON.parse(decryptedValue).value;
        } catch (error) {
            console.error("Error parsing or decrypting cookie:", error);
            return null;
        }
    }
    return null;
};

export const removeCookie = (key: CACHE) => {
    Cookies.remove(key);
};

export const clearAllStorageData = () => {
    Object.keys(Cookies.get()).forEach((cookie) => {
        Cookies.remove(cookie);
    });
    localStorage.clear();
};
